<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter')}}
                </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="filter_by">{{$t('income_report.collected_by')}}</label>
                                <select name="" id="filter_by" v-model="filters.filter_by" type="text" class="custom-select">
                                    <option v-for="(row, index) in period_list" :value="row.slug" :key="'period'+index">{{ row.title }}</option>
                                </select>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('income_report.treasury')}}</label>
                                <multiselect v-model="treasury"
                                             :placeholder="$t('income_report.treasury')"
                                             label="name"
                                             track-by="id"
                                             :options="treasuries"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false">
                                </multiselect>
                            </div>
                            <div class="form-group col-md-6 mb-5">
                                <label>{{$t('income_report.currency')}}</label>
                                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('income_report.from_date')}}</label>
                                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('income_report.to_date')}}</label>
                                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                            </div>

                            <div class="form-group col-md-6 mb-5">
                                <label>{{$t('income_report.user')}}</label>
                                <multiselect v-model="user"
                                             :placeholder="$t('income_report.user')"
                                             label="name"
                                             track-by="id"
                                             :options="users"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getUsers($event)">
                                </multiselect>
                            </div>


                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <!-- End Filter -->
        <div class="card card-custom">
            <div class="card-body">
                <b-tabs content-class="mt-3">

                    <b-tab :title="$t('details')" active>
                        <div class="mt-3">
                            <div class="row mb-10">
                                <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                                    <h4 class="text-center">{{ $t('income_report.income_report')}}</h4>
                                    <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                                </div>
                                <div class="col-md-12 mt-10" v-if="data_line">
                                    <LineChart :data-line="data_line" :currency-name="currency_name"/>
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-12" v-if="data_report_details">
                                    <div class="table-responsive">
                                        <div class="row justify-content-end p-4">
                                            <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                                <download-excel
                                                        class="dropdown-item"
                                                        :fetch="accountingDetailsExport"
                                                        :fields="json_fields_details"
                                                        :name="'income_report.xls'">
                                                    <i class="la la-file-excel"></i>{{$t('excel')}}
                                                </download-excel>
                                                <button class="dropdown-item" @click="printData('summaryTableDetails')">
                                                    <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                                </button>
                                            </b-dropdown>
                                        </div>
                                        <table class="table table-bordered" id="summaryTableDetails">
                                            <thead>
                                            <tr>
                                                <th>{{$t('income_report.date')}}</th>
                                                <th>{{$t('income_report.code')}}</th>
                                                <th>{{$t('income_report.treasury')}}</th>
                                                <th>{{$t('income_report.category')}}</th>
                                                <th>{{$t('income_report.vendor')}}</th>
                                                <th>{{$t('income_report.account')}}</th>
                                                <th>{{$t('income_report.staff')}}</th>
                                                <th>{{$t('income_report.notes')}}</th>
                                                <th>{{$t('income_report.amount')}} ({{currency_name}})</th>

                                            </tr>
                                            </thead>
                                            <tbody>
                                            <template v-for="(row, index) in data_report_details">
                                                <tr :key="'a'+index">
                                                    <td colspan="9">{{row.label}}</td>
                                                </tr>
                                                <tr v-for="(row2, index2) in row.details" :key="'a'+getRandom()+index2">
                                                    <td>{{row2.date}}</td>
                                                    <td>{{row2.code}}</td>
                                                    <td>{{row2.treasury}}</td>
                                                    <td>{{row2.category}}</td>
                                                    <td>{{row2.vendor}}</td>
                                                    <td>{{row2.account}}</td>
                                                    <td>{{row2.staff}}</td>
                                                    <td>{{row2.notes}}</td>
                                                    <td>{{row2.amount}}</td>
                                                </tr>
                                                <tr :key="'b'+index">
                                                    <td colspan="8">{{$t('income_report.total')}}</td>
                                                    <td>{{row.total_amount}}</td>
                                                </tr>
                                            </template>

                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td colspan="8"><b>{{$t('income_report.total')}}</b></td>
                                                <th>{{details_total_sum.sum_total_amount ? details_total_sum.sum_total_amount : 0}}</th>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <!-- <b-tab> -->
                    <!-- </b-tab> -->
                </b-tabs>
                <div class="text-center">
                    <button class="btn btn-warning" @click="loadMore">
                        <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>
                    </button>
                </div>

            </div>
        </div>
        <!--end::supplier-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import LineChart from './LineChart.vue'

    export default {
        name: "index-income-report",
        components:{LineChart},
        data() {
            return {
                mainRoute: '/reports/incomes/report',
                mainRouteDependency: 'base/dependency',

                currency_name: null,
                currencies: [],
                treasuries: [],
                users: [],
                period_list: [
                    {title: this.$t('income_report.daily'), slug: 'daily'},
                    {title: this.$t('income_report.weekly'), slug: 'weekly'},
                    {title: this.$t('income_report.monthly'), slug: 'monthly'},
                    {title: this.$t('income_report.yearly'), slug: 'yearly'},
                ],

                data_line: [],
                data_report_details: [],
                details_total_sum: [],

                filters: {
                    treasury_id: null,
                    from_date: null,
                    to_date: null,
                    user_id: null,
                    currency_id: null,
                    filter_by: null,
                },
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page : 0,

                treasury: null,
                user: null,
            }
        },
        computed: {
            json_fields_details: function () {
                let fields = {};

                fields[this.$t('income_report.date')] = 'date';
                fields[this.$t('income_report.code')] = 'code';
                fields[this.$t('income_report.treasury')] = 'treasury';
                fields[this.$t('income_report.category')] = 'category';
                fields[this.$t('income_report.vendor')] = 'vendor';
                fields[this.$t('income_report.account')] = 'account';
                fields[this.$t('income_report.staff')] = 'staff';
                fields[this.$t('income_report.notes')] = 'notes';
                fields[this.$t('income_report.amount') + ` (${this.currency_name})`] = 'amount';

                return fields;
            },


        },
        watch: {
            treasury: function (val) {
                if (val) {
                    this.filters.treasury_id = val.id;
                } else {
                    this.filters.treasury_id = null;
                }
            },
            user: function (val) {
                if (val) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
        },
        beforeMount() {
            this.getTreasuries();
            this.getAuthUser();
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.accounting_reports'), route: '/reports/accounting-reports'}, {title: this.$t("MENU.income_report")}]);
            this.getCurrencies();
        },
        methods: {

            accountingDetailsExport() {
                this.setReportLog('excel', 'income report');
                let _footer = {
                    date: this.$t('income_report.total') + ` (${this.currency_name})`,
                    code: '',
                    treasury: '',
                    category: '',
                    vendor: '',
                    account: '',
                    staff: '',
                    notes: '',
                    amount: this.details_total_sum.sum_total_amount,
                };
                let _data = [];
                this.data_report_details.forEach((row) => {
                    _data.push({
                        date: row.label,
                        code: '',
                        treasury: '',
                        category: '',
                        vendor: '',
                        account: '',
                        staff: '',
                        notes: '',
                        amount: '',
                    });
                    row.details.forEach((sub_row) => {
                        _data.push({
                            date: sub_row.date,
                            code: sub_row.code,
                            treasury: sub_row.treasury,
                            category: sub_row.category,
                            vendor: sub_row.vendor,
                            account: sub_row.account,
                            staff: sub_row.staff,
                            notes: sub_row.notes,
                            amount: sub_row.amount,
                        });
                    });
                    _data.push({
                        date: this.$t('income_report.total') + ` (${this.currency_name})`,
                        code: '',
                        treasury: '',
                        category: '',
                        vendor: '',
                        account: '',
                        staff: '',
                        notes: '',
                        amount: row.total.total_amount,
                    });
                });
                _data.push(_footer);

                return _data;
            },

            getRandom() {
                return Math.floor(Math.random() * 10000);
            },
            doFilter() {
                this.getReportDetails();
                this.getReportChart();
            },
            resetFilter() {
                this.filters.treasury_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.user_id = null;
                this.filters.currency_id = null;
                this.filters.filter_by = null;
                this.treasury = null;
                this.user = null;

            },
            getAuthUser() {
                ApiService.get(`/get_auth`).then((response) => {
                    this.currency_name = response.data.data.currency_name;
                });
            },

            getTreasuries() {
                ApiService.get(this.mainRouteDependency + "/treasuries").then((response) => {
                    this.treasuries = response.data.data;
                });
            },
            getUsers(filter) {
                if (filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
            },
            loadMore() {
                this.page = this.page + 1;

                this.getReportDetails();
                this.getReportChart();
            },

            /*****
             * details
             */

            getReportDetails() {
                ApiService.get(this.mainRoute, {params: {...this.filters, data_type:'details', page:(this.page ? this.page : 1)}}).then((response) => {
                    this.data_report_details = response.data.data;
                    this.details_total_sum = response.data.total;
                }).then().catch((error) => {this.$errorAlert(error)});
            },
            getReportChart() {
                ApiService.get(this.mainRoute, {params: {...this.filters, data_type:'chart', page:(this.page ? this.page : 1)}}).then((response) => {
                    let _labels =[],_data=[];
                    response.data.data.map((row)=>{
                        _labels.push(row.label);
                        _data.push(row.total);
                    });
                    this.data_line = {labels: _labels, data: _data};
                });
            },
            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },
            printData(tableId) {
                this.setReportLog('pdf', 'income report');
                let divToPrint = document.getElementById(tableId);
                let divTitleToPrint = document.getElementById(tableId + 'Title');
                let newWin = window.open("");
                newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100)
            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
    };
</script>
